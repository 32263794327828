
var startTime = new Date('2023-06-21 8:14:00 AM');
var endTime = new Date('2023-06-22 8:14:00 PM');

var get = (selector) => document.querySelector(selector)
var button = get('#btn-action')

function displayTime() {
  let date = new Date();

  if(date>=startTime && date <= endTime){
    
    if (button.classList.contains("disable")){
      button.classList.remove('disable')
    }
    button.classList.add('enable');
    button.innerHTML = "inscreva-se"
  }
  else if(date > endTime){
    if (button.classList.contains("enable")){
      button.classList.remove('enable');
    }
    button.classList.add('disable');
    button.innerHTML = "Inscrições Encerradas"
    clearInterval(createClock)
  }
}

const createClock = setInterval(displayTime, 1000);

button.addEventListener("click", () =>{
  if (button.classList.contains("enable")){
    window.location.href = 'https://forms.gle/4FQEutWYNQvi7gkSA'; 
  }else{
    let date = new Date();
    if(date>endTime){
      alert("Inscrições encerradas!")
    }else if(date<startTime){
      alert("As inscrições iniciam dia 21/06 às 8h14")
    }
  }
})